@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@media (max-width: 767px) {
  .carsouel-class {
    .img {
      &-card {
        height: 130px !important;
      }
    }
  }
}

// .slick {
//   &-track {
//     display: flex !important;
//   }

//   &-slide {
//     height: inherit !important;
//     & > div {
//       height: 100%;
//       margin: 0 4px;
//       > * {
//         height: 100%;
//       }
//     }
//   }
//   &-rowslider {
//     .slick-slide > div {
//       height: unset;
//       display: flex;
//     }
//     padding: 0;
//     margin: 0;
//   }
//   &-dots {
//     bottom: -1.4rem !important;
//     li {
//       width: unset !important;
//       height: unset !important;
//       margin: 0 0.4 rem !important;
//       button {
//         width: 0.6rem !important;
//         height: 0.6rem !important;
//         padding: 0 !important;
//         background-color: $dark !important;
//         border: 1px solid $dark !important;
//         border-radius: 100%;
//         &::before {
//           display: none;
//         }
//       }
//       &.slick-active {
//         button {
//           background-color: $light !important;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 767.98px) {
//   .slider-gutter-20 {
//     .slick-slide > div {
//       margin: 0 10px;
//     }
//   }
// }
// @media (min-width: 768px) {
//   .slick {
//     &-slide {
//       & > div {
//         margin: 0 15px;
//       }
//     }

//     &-dots {
//       bottom: -3.4rem !important;
//       li {
//         margin: 0 0.6 rem !important;
//         button {
//           width: 0.8rem !important;
//           height: 0.8rem !important;
//           border: 2px solid $dark !important;
//         }
//       }
//     }
//   }
// }

// @media (min-width: 1450px) {
//   .slick {
//     &-list {
//       margin: 0 -15px !important;
//     }
//   }
// }
// *:focus-visible {
//   outline: unset;
// }
.owl-theme .owl-dots .owl-dot span {
  background-color: $dark;
  border: 1px solid $dark;
  width: 6px;
  height: 6px;
  margin: 8px 3px 0;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: $light;
}
.saprator {
  width: 100%;
  height: 5px;
  background-color: black;
  display: block;
}
.owl {
  &-carousel {
    z-index: 0;
  }
}
.equal-height {
  .owl {
    &-stage {
      display: flex;
      align-items: stretch;
    }
    &-item {
      flex: 1;
    }
  }
  .card-reward {
    height: 100%;
  }
}

@media (min-width: 768px) {
  .owl-theme .owl-dots .owl-dot span {
    border: 2px solid $dark;
    width: 10px;
    height: 10px;
    margin: 20px 4px 0;
  }
}

// .slick-slide {
//   height: auto !important; // ← that must not be ignored
// }
// .slick-track {
//   display: flex !important;
//   flex-direction: row !important;
//   flex-wrap: nowrap !important;
//   align-items: stretch !important;
// }
