// Override Bootstrap's Sass default variables
$enable-responsive-font-sizes: true;

// Color system

$grid-gutter-width: 20px;

$primary: #ED1C24;
$secondary: #484848;
$danger: #E41E2B;
$success: #55c043;
$info: #0876bb;
$warning: #fdb913;
$light: #ffffff;
$dark: #000000;
$grey: #f1f1f1;
$grey-2: #d3d3d3;

$theme-colors: (
  'grey': $grey,
  'grey-2': $grey-2,
);

// Customize some defaults
$body-color: $dark;
$body-bg: #f1f2f1;
$btn-hover-bg: #ff0000;
$modal-bg: #00a0e0;
// Spacing
$spacer: 8px;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.5,
  ),
  2: $spacer,
  3: (
    $spacer * 1.5,
  ),
  4: (
    $spacer * 2,
  ),
  5: (
    $spacer * 2.5,
  ),
  6: (
    $spacer * 3,
  ),
  7: (
    $spacer * 3.5,
  ),
  8: (
    $spacer * 4,
  ),
  9: (
    $spacer * 4.5,
  ),
  10: (
    $spacer * 5,
  ),
  11: (
    $spacer * 5.5,
  ),
  12: (
    $spacer * 6,
  ),
  13: (
    $spacer * 6.5,
  ),
  14: (
    $spacer * 7,
  ),
  15: (
    $spacer * 7.5,
  ),
  16: (
    $spacer * 8,
  ),
  17: (
    $spacer * 8.5,
  ),
  18: (
    $spacer * 9,
  ),
);

// Typography

$font-size-base: 1rem;
$font-family-base: 'TCCC-UnityText';

$headings-color: $dark;
$headings-font-weight: 500;
$headings-margin-bottom: unset;

$small-font-size: 80% !default;

$h1-font-size: 2rem;
$h2-font-size: 1.8rem;
$h3-font-size: 1.6rem;
$h4-font-size: 1.4rem;
$h5-font-size: 1.2rem;
$h6-font-size: 2rem;

// Links color
$link-color: #1985d3;
$link-hover-color: #1985d3;
$link-hover-decoration: none;

// Components
//
// Define common padding and border radius sizes and more.

$border-color: grey;

$border-radius: 2.5rem;

// Forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$label-margin-bottom: 0.5rem !default;

$input-btn-padding-y: 1.7rem;
$input-btn-padding-x: 1.7rem !default;
$input-btn-font-family: 'TCCC-UnityText';
$input-btn-font-size: 1.4rem;
$input-btn-line-height: 1;

$input-btn-focus-width: 0;
$input-btn-focus-color: unset;
$input-btn-focus-box-shadow: unset;

$input-disabled-bg: $light;

$input-color: $dark;
$input-border-color: $light;
$input-box-shadow: unset;

$input-focus-border-color: $light;
$input-focus-color: $dark;

$input-placeholder-color: grey;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 1.5rem;
$btn-padding-x: 3.2rem;
$btn-font-size: 1.6rem;
$btn-line-height: 1;
$btn-font-weight: 500;

$btn-padding-y-sm: 1.2rem;
$btn-padding-x-sm: 2.4rem;
$btn-font-size-sm: 1.2rem;
$btn-line-height-sm: 1;

$btn-border-width: 0;

$btn-font-weight: 600;
$btn-box-shadow: unset;
$btn-focus-width: 0;
$btn-disabled-opacity: 0.6;

$btn-link-disabled-color: muted;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 10rem;
$btn-border-radius-sm: 0.8rem;

// Modal
$modal-md: 490px !default;
$modal-backdrop-bg: #150100 !default;
$modal-backdrop-opacity: 0.8 !default;

// Cards
$card-border-radius: 1rem;
