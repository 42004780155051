.btn {
  border: 1px solid transparent;
  font-size: 1.6rem;
  padding: 1.5rem 1.6rem;
  &-refer {
    padding: 0.7rem 1.8rem;
  }
  &-selected {
    font-size: 1rem;
    padding: 8px;
  }
  &-modal {
    padding: 1rem 4rem !important;
  }
  &-follow {
    padding: 0rem 2.4rem;
    font-size: 1.2rem;
    height: 34px;
    &-container {
      display: flex;
      justify-content: flex-end;
      margin-top: -55px;
      z-index: 999;
      position: fixed;
      right: 16px;
    }
  }
  &-ugc-submit {
    width: 100%;
  }
  &-grey {
    .s-header {
      display: none;
    }
    .container {
      padding: 0;
    }
    .progress-label {
      font-size: 6px;
      position: relative;
      top: -3px;
    }
    .progress {
      width: 100%;
      &-bar {
        height: 4px;
        line-height: 14px;
      }
    }
  }
  &-fixed {
    position: fixed;
    bottom: 100px;
    width: 90%;
    transform: translate(18px, 10px);
    display: block;
  }
  &-primary,
  &-outline-primary {
    border-color: $primary;
    padding: 1.2rem 2.2rem;
    font-size: 1.2rem;
    white-space: nowrap;
    @media (max-width: 400px) {
      font-size: 3.45vw;
    }
  }

  &-outline-dark {
    border-color: $dark;
    color: $dark;

    &:hover {
      color: $light;
    }
  }

  &-underline {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &-link {
    font-size: 1rem;
    font-weight: normal;

    &-lg {
      font-size: 1.4rem;
      line-height: 1;
    }
  }

  &-lg {
    padding: 2rem 1.6rem 2.2rem;
  }

  &-ex-lg {
    padding: 1.4rem 6rem;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      width: 2rem;
      height: 2rem;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      font-size: 11px;
      font-weight: 900;
    }
  }
}
.check-btn-profile {
  font-size: 1rem;
  padding: 7px 17px;
}
.artist {
  &-detail {
    .btn {
      &-follow {
        &-container {
          margin-top: -32px;
        }
      }
    }
  }
}
.upload-btn {
  .footer-pointbox {
    line-height: 1.7rem;
    .points-black {
      font-size: 0.9rem;
    }
    .info-card-sign {
      width: 1.3rem;
    }
    .points-num {
      line-height: 0.7;
      padding: 0 0.2rem 0 0.5rem;
    }
    & > span {
      font-size: 0.9rem;
      font-weight: 400;
      white-space: nowrap;
      @media (max-width: 400px) {
        font-size: 2.54vw;
      }
    }
  }
  .title {
    font-size: 1.2rem;
  }
  .upload-icon {
    width: 1.6rem;
  }
}

@media (min-width: 768px) {
  .btn {
    padding: 1.5rem 3.2rem;
    font-size: 1.6rem;
    &-modal {
      padding: 1.6rem 4rem !important;
    }
    &-ugc-submit {
      width: 464px;
    }
    &-fixed {
      padding: 1.6rem 4.4rem;
      display: flex;
      justify-content: center;
      width: 20%;
      position: unset;
      margin: 0 auto;
    }
    &-link {
      font-size: 1.6rem;
      font-weight: 500;
    }

    &-ex-lg {
      padding: 1.8rem 13.8rem;
    }
  }
  .check-btn-profile {
    font-size: 1.6rem;
  }
  .upload-btn {
    .footer-pointbox {
      & > span {
        font-size: 1.2rem;
      }
    }
    .title {
      font-size: 2rem;
    }
    .upload-icon {
      width: 2.9rem;
    }
    & > img {
      width: 1.2rem;
    }
  }
}
.home {
  &-page {
    .upload-btn {
      background-color: $primary;
    }
  }
}
.st-btn.st-first,
.st-btn.st-last {
  min-width: unset !important;
  border-radius: 100% !important;
  border: 2px solid grey !important;
  padding: 10px;
  width: 50px !important;
  height: 50px !important;
  background-color: transparent !important;
  img {
    background-color: #4267b2;
    border-radius: 100% !important;
    width: 35px !important;
    height: 26px !important;
  }
  .st-label {
    display: none !important;
  }
}
.st-btn.st-last.st-remove-label {
  background-color: transparent !important;
  border: none !important;
  img {
    background-color: transparent !important;
  }
  .bg-transparent {
    background-color: transparent !important;
  }
}
.st-btn.st-remove-label {
  background-color: transparent !important;
  min-width: unset !important;
  border-radius: 100% !important;
  border: 2px solid grey !important;
  padding: 10px;
  width: 50px !important;
  height: 50px !important;
  background-color: transparent !important;
  img {
    background-color: #25d366 !important;
    border-radius: 100% !important;
    width: 28px !important;
    height: 26px !important;
    padding: 2px;
  }
}
