.card-image-container {
  text-align: center;
  padding: 0 2rem;
  background-color: $light;

  img {
    width: 17vw;
  }
}

.edit-profile-form {
  .dropdown-center {
    text-align: left;
  }

  .signup-avatar-wrapper.avatar-wrapper + .dropdown-menu {
    left: 0 !important;
    transform: translate(0%, 55px) !important;
  }

  .mobile-input-wrapper .form-control {
    background-color: #fff;
  }
}

.avatar {
  &-wrapper {
    padding-left: 2rem;
    position: relative;

    .avatar-edit {
      bottom: 0;
      right: 0;
      position: absolute;
    }

    & + .dropdown {
      &-menu {
        border-radius: 5px;
        max-width: 175px;
        width: 100%;
        border: 1px solid $grey-2;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
        margin-top: 0;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (min-width: 767px) {
          max-width: 239px;
        }
        img {
          margin: 0.2rem;
          max-width: 3.7rem;
        }

        &:after {
          left: 50%;
          transform: translateX(-50%);
          display: none;
        }

        .dropdown-item {
          color: $dark;
          font-size: 1.2rem !important;
          padding: 0.7rem 0.3rem;
          font-weight: 600;
          border-bottom: 1px solid $grey;
          cursor: pointer;
          @media (min-width: 767.98px) {
            font-size: 1.6rem !important;
            padding: 1.2rem;
          }
          &:last-child {
            border: unset;
          }
        }
      }

      &-menu:not(.show) {
        display: none;
      }
    }
  }

  &-img {
    width: 5.3rem;
    height: auto;
    border-radius: 100%;
  }
}

.signup-avatar-wrapper {
  &.avatar-wrapper + .dropdown-menu {
    left: 50% !important;
    transform: translate(-50%, 55px) !important;
  }
}

.avatar-select-wrapper {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 66%;
  max-width: 190px;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 2;
  background-color: $light;
  border: 1px solid $grey;

  img {
    margin: 0.2rem;
    max-width: 3.7rem;
    cursor: pointer;
  }
}

//Profile Web Camera
.camera-wrapper {
  overflow: hidden;

  video {
    height: auto;
    overflow: hidden;
    width: 100%;
    padding: 1rem;
  }
}

.profile-card {
  width: 100% !important;

  &.wallet {
    padding: 1.2rem;
  }
}

.college-minicert {
  .btn-outline-primary {
    background-color: $light;
    font-size: 10px;

    &:hover {
      background-color: $primary;
    }
  }

  .wallet {
    h3 {
      font-size: 16px;
    }
  }

  h4 {
    font-size: 1.2rem !important;
    font-weight: 400;
  }

  .popover {
    width: 22rem;
    transform: translateX(-10%);
    top: 130%;
    border-color: $light;
    font-size: 1rem;

    .tooltip-arrow {
      border-color: transparent transparent $light transparent;
      top: -15px;
      border-width: 9px;
      left: 21px;
    }
  }

  .first-letter {
    background-color: rgba(223, 49, 37, 0.2);
    color: #e41e2b;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 700;
    padding: 7px 8px;
  }

  .cs-coin-image {
    width: 2.4rem !important;
    margin-left: 0 !important;
    cursor: pointer;
  }

  .search-wrapper {
    display: flex;
    border: 1px solid #cdcdcd;
    padding: 1.1rem;
    border-radius: 100px;
    background-color: #fff;
    overflow: hidden;
    max-width: 397px;

    &.search-focused {
      border-radius: 8px;
    }

    .search {
      border: none;
      width: 100%;
    }

    .search:focus {
      outline: none;
      padding: 4px;
    }

    .search-icon {
      display: block;
    }

    .search:focus + .search-icon {
      display: none;
    }
  }
}

.profile-header {
  padding-top: 15px;
  padding-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .profile-card {
    h4 {
      font-size: 2rem;
    }

    &.wallet {
      padding: 2.1rem 2.8rem;
    }

    .points-num-sign {
      font-size: 1.6rem;
    }

    .points-box {
      img {
        width: 9px;
      }
    }
  }

  .profile-points-sign {
    width: 5.2rem !important;
  }

  .points-num-sign {
    font-size: 1.3rem;
    padding: 0.1 0.7rem 0.1 1.2rem !important;
  }

  .walletimgdiv {
    display: flex !important;
    flex-direction: column;
    align-items: end;

    img {
      width: 2.6rem;
    }

    h4 {
      text-align: end;
      text-wrap: nowrap;
      margin-bottom: 0.75rem;
    }
  }

  .profile-header {
    // padding-left: 4rem !important;
    text-align: left;
    padding-top: 5.8rem;
  }

  .profile-header-container {
    padding-left: 0rem !important;
  }

  .user-name {
    font-size: 1.8rem;
  }

  .user-mobile {
    font-size: 1.3rem;
  }

  .user-email {
    font-size: 2rem;
  }

  .college-minicert {
    .btn-outline-primary {
      font-size: 1.6rem;
    }

    .wallet {
      h3 {
        font-size: 20px;
      }
    }

    h4 {
      font-size: 1.6rem !important;
    }

    .first-letter {
      font-size: 2.3rem;
      padding: 10px 12px;
    }

    .cs-coin-image {
      width: 2.4rem !important;
    }

    .search-wrapper {
      padding: 1.5rem 1.9rem;
      overflow: hidden;
      max-width: 397px;

      &.search-focused {
        border-radius: 8px;
      }

      .search:focus {
        padding: 4px;
      }
    }
  }

  .edit-profile-form {
    .dropdown-center {
      text-align: center;
    }

    .signup-avatar-wrapper.avatar-wrapper + .dropdown-menu {
      left: 50% !important;
      transform: translate(-50%, 55px) !important;
    }

    .mobile-input-wrapper .form-control {
      background-color: #fff;
    }
  }
}

.custom-table {
  tbody,
  thead {
    th,
    td {
      border: none;
      font-size: 1.2rem;
      padding: 1rem;
      @media (max-width: 374px) {
        font-size: 1rem;
      }
    }

    th,
    td {
      margin-bottom: 0.8rem;

      &:first-child {
        border-radius: 01rem 0 0 1rem;
        padding-left: 2rem;
      }

      &:last-child {
        border-radius: 0 1rem 1rem 0;
        padding-right: 2rem;
        text-align: end;
      }
    }
  }

  th {
    background: $dark;
    color: $light;
  }

  .pending {
    background-color: $light;
  }

  .accept {
    background-color: #f1f1f1;
    border: 1px solid #fff;
  }
}

.profile-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;

  thead {
    th {
      background-color: $dark;

      // font-size: 1.2rem;
      &:first-child {
        text-align: left;
      }
    }
  }

  tbody {
    td {
      background-color: $light;

      // font-size: 1.6rem;
      &:first-child {
        text-align: left;
      }
    }
  }

  @media (min-width: 576px) {
    table-layout: fixed;
  }
}

.refer-info {
  h3 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1rem;
    color: #484848;
  }

  @media (min-width: 767.98px) {
    h3 {
      font-size: 2.8rem;
    }

    p {
      font-size: 2rem;
    }

    .custom-table {
      th,
      td {
        font-size: 1.6rem;
        padding: 1.4rem;
      }
    }
  }
}

.my-profile-header {
  .s-title {
    text-align: left !important;
  }

  @media (min-width: 768px) {
    padding: 0 3.6rem !important;
  }

  @media (min-width: 1440px) {
    padding: 0 4.4rem !important;
  }
}

.my-profile {
  // background-color: white;
  p.info-text {
    display: none;
  }
  .popover-container {
    display: flex !important;
    min-width: 363px;
    justify-content: flex-end;
    @media (max-width: 767px) {
      min-width: 234px;
    }
    @media (max-width: 400px) {
      min-width: 184px;
    }
  }
  .popover {
    transform: translateX(-5%) !important;
  }

  .edit-icon {
    position: absolute;
    top: 19px;
    right: 0;
    width: 1.2rem;

    @media (min-width: 768px) {
      width: 1.8rem;
    }
  }

  .moredetailtext {
    font-weight: 10;
    text-align: center;
  }

  .s-sub-title {
    color: #484848;
  }

  .profile-mobile-sign {
    min-width: 1.5rem;
  }

  .profile-address-sign {
    min-width: 1.8rem;
    align-self: self-start;
    margin-top: 0.2rem;
  }

  .mobile-no-info {
    font-size: 1.4rem;
    padding-left: 1rem;
  }

  .address-tag {
    font-size: 1.3rem;
    font-weight: 10;
  }

  .default-address-sign {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.8rem;
  }

  .default-address-div {
    width: 96vw;
    position: relative;
    left: 57%;
    right: 49%;
    margin-left: -51vw;
    padding: 1.2rem;
    border-radius: 1rem;

    @media (min-width: 445px) {
      width: 95vw;
    }

    @media (min-width: 575px) {
      width: 93vw;
      margin-left: -51vw;
    }

    @media (min-width: 768px) {
      width: 83vw;
      margin-left: -45vw;
    }

    @media (min-width: 925px) {
      width: 86vw;
      margin-left: -47vw;
    }

    @media (min-width: 1225px) {
      width: 89vw;
      margin-left: -49vw;
    }

    @media (min-width: 1468px) {
      width: 84.5vw;
      margin-left: -47vw;
    }

    @media (min-width: 1600px) {
      width: 77vw;
      margin-left: -43vw;
    }
  }

  .cs-coin-image {
    @media (min-width: 768px) {
      width: 3rem;
      margin-left: 1rem;
    }
  }

  .collect-more {
    cursor: pointer;
  }

  .collectmore-outer {
    @media (min-width: 768px) {
      padding: 0 5rem;
    }
  }
}

@media (max-width: 767.98px) {
  .edit-profile-form {
    .avatar-select-wrapper {
      left: 0;
      transform: unset;
    }
  }
}
@media (max-width: 575.98px) {
  .coinstext {
    font-size: 1.7rem !important;
  }
}

.profile-img-div {
  position: relative;
}

.fan-badges {
  width: 25px !important;
  max-height: auto;
}

.user-profile-image {
  max-width: 5.6rem;
  // border: 5px solid #16c43d;
  border-radius: 50%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;

  @media (max-width: 475px) {
    max-width: 5.6rem;
  }

  @media (min-width: 768px) {
    max-width: 11rem;
    width: 100%;
  }
}

.profile-progress-bar {
  width: 6.2rem !important;

  @media (min-width: 475px) {
    width: 6.2rem !important;
  }

  @media (min-width: 768px) {
    width: 12rem !important;
  }
}

.profile-completion {
  position: absolute;
  background-color: #16c43d;
  padding: 0.15rem 1.2rem;
  color: #fff;
  width: 4.5rem;
  display: flex;
  justify-content: center;
  left: 14%;
  top: 83%;
  border-radius: 2rem;

  @media (max-width: 475px) {
    padding: 0.15rem 1rem;
    color: #fff;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    left: 15%;
    top: 82%;
  }

  @media (min-width: 768px) {
    left: 30%;
    top: 87%;
  }
}

.user-mobile {
  font-size: 1.2rem;
}

.user-email,
.user-mobile {
  color: $dark;
  font-style: normal;
  font-weight: 400;
}

.s-sub-title {
  color: #484848;
}

.refer-now-btn {
  height: 4.75rem;
  padding: 1rem 1.8rem;
  white-space: nowrap;
}

.address-add-btn {
  color: #1985d3;
  background-color: $light;

  &:disabled {
    cursor: default;
    color: gray;
  }

  &:hover {
    background-color: $light;
  }
}

.address-tag {
  color: #000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 100;
  line-height: 0.875rem;
}

.default-address-label {
  font-size: 1.3rem;
  color: #000;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}

.address-font-style {
  color: #000;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  font-size: 1.4rem;

  img {
    height: 15px;
    margin-left: 1.7rem;
  }
}

.profile-name-display {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2rem;
  /* 160% */
  text-wrap: wrap;
  word-wrap: break-word;
  word-break: break-word;
  margin-top: 0.8rem;
  max-width: 7.8rem;

  img {
    width: 10px;
  }
}

.refer-btns {
  width: 17rem;
  text-wrap: nowrap;

  @media (min-width: 768px) {
    width: 18rem;
  }
}

.address-divider {
  border-bottom: 1px solid #cdcdcd;
}

@media (min-width: 768px) {
  .profile-name-display {
    font-size: 2rem;
    margin-top: 1.2rem;
    max-width: 136px;
    text-wrap: wrap;
    line-height: 2.4rem;

    img {
      width: 16px;
    }
  }

  .custom-table {
    tbody,
    thead {
      th,
      td {
        font-size: 1.6rem;
        padding: 1.4rem;
      }
    }
  }

  .my-profile {
    .address-font-style {
      img {
        height: 22px;
      }
    }

    .address-tag {
      font-size: 2rem;
      font-weight: 700;
    }

    .address-add-btn,
    .address-font-style,
    .mobile-no-info,
    .default-address-label {
      font-size: 2rem;
    }
  }

  .modal-add-address {
    .form-check + label::before {
      top: 0;
    }
  }
}

@media (max-width: 576px) {
  .refer-table {
    thead {
      th {
        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          border-radius: 1rem 0 0 1rem;
        }
      }
    }

    tbody {
      td {
        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          border-radius: 1rem 0 0 1rem !important;
        }
      }
    }
  }
}
