.wallet-card {
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-left: 10px;
}

.wallet-body {
  padding: 1.5rem;
  padding-bottom: 25px;
}

.wallet-img {
  width: 53.5px;
}

.wallet-top-img {
  width: 53.5px;
}

.red-sign {
  width: 18px;
}

.total-pt {
  color: red;
}

#wallet_detail header {
  padding-top: 10px;
}

.coins-container {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
}
.contest-clock-link {
  position: fixed;
  top: 162px;
  right: -1px;
  cursor: pointer;
}

.reward-card {
  position: relative;
  border-radius: 0 0 1rem 1rem;

  &-body {
    padding: 20px 25px 10px 25px !important;
  }

  &-title {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
  #wallet_detail .s-title {
    display: none;
  }

  #wallet_detail .s-sub-title {
    display: none;
  }

  .wallet-mb {
    display: none;
  }

  .redeem-list-mb {
    margin-top: 40px;
  }
  .popover-container {
    .info-icon {
      width: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .wallet-desk {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  #wallet_detail .s-title {
    display: none;
  }

  #wallet_detail .s-sub-title {
    display: none;
  }

  #faq-wallet {
    .mt-sm-15 {
      margin-top: 25px !important;
    }
  }

  .reward-card {
    &-body {
      padding-top: 35px !important;
    }

    &-title {
      font-size: 2.4rem;
    }
  }
}
.total-coins {
  h1 {
    font-size: 2.8rem;
  }
  img {
    width: 25px;
    height: 25px;
  }
}

.s {
  &-wallet-slider,
  &-header-left-mobile {
    .s-title {
      text-align: left !important;
    }

    .container-fluid {
      padding: 0 26px;
    }
  }
  &-header-contest {
    .s-sub-title {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .wallet-desk {
    display: none;
  }

  .wallet-mb {
    margin-top: -10px;
    border-radius: 0px;
  }
}

@media screen and (max-width: 550px) {
  .wallet-img {
    width: 40.5px;
  }

  .wallet-top-img {
    width: 53.5px;
  }
}

@media screen and (max-width: 400px) {
  .wallet-img {
    width: 30.5px;
  }

  .wallet-top-img {
    width: 53.5px;
  }
}

#faq-wallet {
  .page-header {
    display: none;
  }

  .mt-sm-15 {
    margin-top: 25px !important;
  }
}

#faq-wallet header {
  display: none !important;
}

#wallet_detail .slick-center {
  transform: scale(1.1);
  z-index: 10000;
  transition: all 400ms ease-in-out;
}

#wallet_detail {
  .slick-list,
  .slick-track,
  .slick-list {
    padding: 1.5rem 0;
    padding-left: 15px;
  }
}

.bar-container {
  width: 20px;
  height: 200px;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  /* Make sure the child can't overflow the container */
}

.percentage-bar {
  width: 100%;
  background-color: #3498db;
  position: absolute;
  bottom: 0;
  transition: height 0.5s ease;
  /* Optional: Add a smooth transition effect */
}

.opacity {
  opacity: 0.6;
}

.opacity-img {
  opacity: 0.7;
}

.opa {
  opacity: 0.5;
}

.Redeem-status {
  display: none;
}

.Redeem-active {
  display: none !important;
}

.Button-active {
  display: none !important;
}

.earn-opacity {
  opacity: 0.5;
}

.logo-adj {
  margin-top: -3.2rem !important;
}

.wallet-header {
  // padding:5rem 12rem 5rem 12rem;
  border-radius: 0px !important;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: -2px;
}

@media screen and (max-width: 767px) {
  .logo-adj {
    margin-top: -1.8rem !important;
  }
}

@media screen and (max-width: 350px) {
  .wallet-btn {
    font-size: 10px;
  }
}

.eligible-card {
  display: none !important;
}

// .wallet-slider-set{
//   padding-right:32px !important;
// }

.wallet-coin-font {
  font-size: 1.75rem;
}

@media screen and (min-width: 1200px) {
  .wallet-slider-set {
    padding-right: 32px !important;
  }
}

@media screen and (min-width: 800px) {
  .wallet-slider-set {
    padding-right: 28px !important;
  }
}

.history-card {
  background-color: white;
  border-radius: 1rem;
  padding-top: 30px;
  padding-bottom: 30px;
}

.points-wallet {
  &-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.2rem 0;
  }

  &-title {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: $secondary;
    padding-right: 0.5rem;
    font-weight: normal;
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey;
    @include border-radius(1rem);
  }

  &-num {
    font-size: 1rem;
    line-height: 1.4rem;
    color: $secondary;
    padding: 0 0.5rem 0 1rem;
    margin: 0;
    font-weight: normal;
  }
}

@media (min-width: 768px) {
  .points-wallet {
    &-block {
      margin: 2.8rem 0;
    }

    &-title {
      font-size: 1.6rem;
      line-height: 1.8rem;
      padding-right: 1rem;
    }

    &-box {
      @include border-radius(2.6rem);
    }

    &-num {
      font-size: 1.5rem;
      line-height: 1;
      color: $secondary;
      padding: 0 1rem 0 2rem;
    }

    &-sign {
      width: 2.8rem;
    }
  }
  .s {
    &-wallet-slider {
      padding-top: 5.6rem;
      .container-fluid {
        padding: 0 45px;
      }
    }
    &-header-left-mobile {
      .s-title {
        text-align: center !important;
      }
    }
    &-header-contest {
      .s-sub-title {
        display: block;
      }
      .btn-link {
        display: none;
      }
    }
  }
  .total-coins {
    h1 {
      font-size: 6rem;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}

// (min-width: 768px)

.disable {
  display: none !important;
}

.opacity-wallet {
  opacity: 0.5;
}

// home changes

.tabs {
  display: flex;
  padding-bottom: 11px;
}

.tab {
  display: inline-block;
  position: relative;
  padding: 10px 10px;
  cursor: pointer;
  color: #484848;
  text-decoration: none;
  margin-right: 15px;
  h3 {
    color: #484848;
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.tab.active {
  color: $primary;

  h3 {
    color: $primary;
    font-weight: 600;
  }
}

.tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tab.active::before {
  background-color: $primary;
}

// home artist chANGE

.artist-img {
  width: 50px;
  border-radius: 50px;
}

.artist-name {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

.artist-follow {
  color: #484848;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

@media (min-width: 768px) {
  .tabs {
    padding-bottom: 2.4rem;
  }

  .tab {
    margin-right: 2.4rem;
    h3 {
      font-size: 2.4rem;
    }
  }
}
@media (max-width: 380px) {
  .wallet-reward-btn {
    font-size: 9px;
  }
}

.image-container {
  display: inline-block;
  padding: 5px;
  /* Adjust the padding to control the frame size */
  background-color: white;
  border-radius: 50px;
}

.image-with-frame {
  // width: 100%;
  // height: 100%;
  object-fit: cover;
  /* Maintain aspect ratio while covering the container */
}

//percentage

// .percentage-bar-container {
//   margin: 20px;
// }

// .percentage-bar {
//   height: 20px;
//   background-color: #3498db;  /* Adjust the color as needed */
//   position: relative;
// }

// .marks {
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
// }

// .mark {
//   position: absolute;
//   transform: translateX(-50%);
//   background-color: #e74c3c;  /* Adjust the color as needed */
//   color: #fff;
//   padding: 5px;
//   border-radius: 3px;
//   font-size: 12px;
// }

// .percentage-text {
//   margin-top: 5px;
//   font-size: 14px;
//   text-align: center;
// }

.underline {
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
  margin-top: 10px;
}

.info-icon {
  width: 10px;
  margin-left: 5px;
}

// .reward-body{
//   padding: 10px 10px 10px 10px !important;
// }

.popover-container {
  position: relative;
  min-width: 25px;
}

.popover {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e6e6e6;
  color: black;
  border-radius: 5px;
  z-index: 1;
  padding: 10px !important;
}

.tooltip-arrow {
  position: absolute;
  top: -11Epx;
  left: 80%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e6e6e6 transparent;
}
