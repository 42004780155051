.block {
  &-middle {
    text-align: center;
    .icon {
      margin-bottom: 1rem;
      font-size: 5.5rem;
      color: $secondary;
    }
    .title {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .sub-title {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: normal;
    }
  }
}
.pagination-container {
  cursor: pointer;
  font-size: 1.3rem;
}

.current-page {
  border-radius: 2px;
  background: #000;
  color: #fff;
  text-align: center;
  width: 2.5rem;
}
