.dropdown {
  &-item {
    color: #535353;
    font-size: 1.1rem;
    line-height: 1;
    padding: 0.6rem;
    &:hover {
      background-color: initial;
    }
    &:active {
      color: initial;
    }
    &:focus-visible {
      outline-offset: unset;
      outline: unset;
    }
  }
  &-menu {
    border: unset;
    padding-left: 1.8rem;
  }
  &-toggle {
    // padding-right: 2rem !important;
    &::after {
      border: solid black;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      top: calc(50% - 5px);
      margin-left: 0.2rem;
      position: absolute;
      right: 7px;
    }
    &.show {
      &::after {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: calc(50% - 8px);
        margin-left: 0.8rem;
        @media (min-width: 768px) {
          right: 0;
        }
      }
    }
  }
  &-arrow-disable {
    &::after {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .dropdown {
    &-item {
      color: $dark;
      font-size: 1.6rem !important;
      padding: 1.2rem;
      border-bottom: 1px solid $grey;
      &:first-child {
        border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
    &-menu {
      border-radius: 5px;
      border: 1px solid $grey-2;
      margin-top: 2rem;
      padding: 0;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 2rem;
        bottom: calc(100% - 2px);
        width: 2rem;
        height: 3rem;
        background: url("~/public/assets/img/icons/top-arrow.svg") no-repeat
          center bottom;
      }
    }
    &-toggle {
      &::after {
        padding: 5px;
      }
    }
  }
}
.dropdown-visible {
  width: 100%;
  .btn {
    &-dropdown {
      color: #000;
      background: transparent;
      border: 1px solid $dark;
      width: 100%;
      text-align: left;
      &:hover,
      :focus,
      :focus-visible,
      :active,
      .active {
        background-color: transparent !important;
        border-color: $dark;
        color: $dark !important;
        box-shadow: none;
      }
    }
  }
  .dropdown-toggle::after {
    right: 15px;
  }
  .dropdown-menu.show {
    width: 100%;
    padding-left: 6px;
  }
  .dropdown-item {
    padding: 1rem;
  }
}
