// .s is represent here "Section"
.s {
  &-header {
    padding: 3rem 0 1.2rem;
    .container-fluid {
      position: relative;
      padding: 0 10rem 0 2rem;
    }
    .btn-link {
      position: absolute;
      right: 2rem;
      top: 0;
      cursor: pointer;
    }
  }
  &-title {
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: 500;
    padding-bottom: 0.4rem;
    text-align: center;
    &.md {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  &-sub-title {
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: normal;
    color: $secondary;
    &.sm {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    &.md {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
  &-signup{
    .form-group {
      &:nth-child(6){
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media (min-width: 768px) {
  .s {
    &-header {
      padding: 8.8rem 0 2.4rem;
      text-align: center;
      .container-fluid {
        position: relative;
        padding: 0 9rem;
      }
      .btn-link {
        top: auto;
        bottom: 0;
        right: 3.2rem;
      }
    }
    &-title {
      font-size: 2.8rem;
      line-height: 3.6rem;
      padding-bottom: 1.2rem;
      &.md {
        font-size: 2rem;
        line-height: 1;
      }
      &-small {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
    &-sub-title {
      font-size: 2rem;
      line-height: 2.4rem;
      &.sm {
        font-size: 1.2rem;
        line-height: 1.6;
      }
      &.md {
        font-size: 1.4rem;
        line-height: 1;
      }
      &-small {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}
