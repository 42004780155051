.follow-us-block {
  background-color: $dark;
  padding: 1.5rem;
  border-radius: 1rem;
  .title {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $light;
    text-align: center;
    margin-bottom: 2.5rem;
  }
}
