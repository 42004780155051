.ghknmB{
    display: none!important;
}
.tour-text{
  color: #000;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tour-btn-container{
  margin-top: 2rem;
    display: flex;
    div{
        margin-right: 1.12rem;
    }
    .tour-btn-link{
      color: #000;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
    }
}