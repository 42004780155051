.ugc-page {
  .check {
    &-link {
      span {
        margin-right: 0.4rem;
        font-weight: 400;
        font-size: 1.6rem;
      }
      img {
        width: 10px;
      }
    }
  }
  .img {
    &-block,
    &-card {
      border-radius: 0;
      height: 152px;
      object-fit: cover;
    }
  }

  @media (min-width: 767.98px) {
    .img {
      &-block,
      &-card {
        border-radius: 2rem;
        height: 267px;
      }
    }

    .tabs {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
.ugc-upload {
  border: 1px solid #cdcdcd;
  border-radius: 1rem;
  .percentage-text {
    margin: 0;
    position: absolute;
    left: 0;
    top: 57px;
    text-align: center;
    width: 100%;
  }
  .text-format {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.creator-table-wrapper {
  .s-header {
    max-width: 66.66%;
    margin: 0 auto;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .title-img-wrapper {
    justify-content: flex-start;
  }
}

.reels {
  // width: 818.822px;
  //   height: 1053.355px;
  //   position: relative;
  &-page {
    video {
      background-color: $dark;
    }
  }
  &-back {
    color: #fff;
    font-size: 30px;
    position: absolute;
    top: 30px;
    left: 45px;
  }

  &-profile {
    font-size: 20px;
    color: #fff;
    margin-left: 15px;
  }

  &-icons {
    text-align: center;
    right: 0;

    .icon,
    img {
      width: 24px;
      height: 24px;
    }

    p {
      all: unset;
      color: #fff;

      &-num {
        font-size: 16px;
      }

      &-like,
      &-view {
        font-size: 30px;
      }
    }
  }

  &-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.reels {
  &-profile,
  &-icons {
    position: absolute;
    bottom: 50px;
    z-index: 1;
    @media screen {
      bottom: 80px;
    }
  }

  &-profile {
    display: flex;

    align-items: center;
  }

  &-back {
    color: #fff;
    font-size: 30px;
    position: absolute;
    top: 30px;
    left: 35px;
  }

  &-profile {
    font-size: 14px;
    color: #fff;
    margin-left: 15px;
  }

  &-icons {
    text-align: center;
    right: 20px;

    .icon,
    p {
      all: unset;
      color: #fff;

      &-num {
        font-size: 16px;
      }

      &-like,
      &-view {
        font-size: 28px;
      }
    }
    .icon-num {
      font-size: 12px;
      color: rgb(255, 255, 255);
    }
  }

  &-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  iframe {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
  }
}

.video {
  &-container {
    position: relative;
    height: 1050px;
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
    border-radius: 20px;
    background-color: white;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;

    &::after {
      content: "";
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.33%, #000 127.95%);
      height: 300px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-player {
    height: 100%;
    width: 100%;
  }

  &-card {
    position: relative;
    height: 100%;
    margin-bottom: 20px;
  }
}
@media (min-width: 767.98px) {
  .video {
    &-card {
      height: unset;
    }
    &-container {
      height: unset;
    }
  }
}
.search-filter-wrapper {
  display: flex;
  border: 1px solid #cdcdcd;
  padding: 1.1rem;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;

  // &.search-focused {
  //   border-radius: 8px;
  // }

  .search {
    border: none;
    width: 100%;
    font-size: 1.2rem;

    &::placeholder {
      font-size: 1.2rem;
    }
  }

  // .search:focus {
  //   outline: none;
  //   padding: 4px;
  // }

  .search-icon {
    display: block;
  }

  .search:focus + .search-icon {
    display: none;
  }
}

@media (min-width: 767.98px) {
  .ugc-upload {
    border-radius: 1.5rem;
    p {
      font-size: 1.6rem;
    }
    img {
      width: 10rem;
    }
    .percentage-text {
      top: 102px;
    }
  }
}

@media (max-width: 767.98px) {
  .reels {
    &-back {
      left: 15px;
    }
  }

  .video {
    &-container {
      border-radius: 0;
      &.reels {
        height: 100vh;
      }
    }
  }

  .ugc-page {
    background-color: $light;
    .check {
      &-link {
        font-size: 0.8rem;
        span {
          margin-right: 0.4rem;
          font-weight: 400;
          font-size: 1rem;
        }
        img {
          width: unset;
        }
      }
    }
    .search-filter-wrapper {
      border-radius: 100px;
      padding: 0.5rem 1.1rem;
    }

    .btn-filter {
      background-color: $light;
    }

    .tabs {
      padding-bottom: 0;
    }
  }

  .creator-table-wrapper {
    .s-header {
      max-width: 100%;
    }
  }
}
.icon {
  &-like {
    width: 16px !important;
    @media (min-width: 767.98px) {
      width: 25px !important;
    }
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
// .img-slider {
//   .img {
//     &-card {
//       height: 180px;
//       object-fit: cover;
//       @media (min-width: 767.98px) {
//         height: 320px;
//       }
//     }
//   }
// }

@media (max-width: 768px) {
  .full-screen-video {
    height: 100vh;
    width: 100%;
    .icon-share-01 {
      flex-direction: column;
      display: flex;
    }
    .img {
      &-card {
        height: 100vh;
      }
    }
  }

  .slider-container {
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }

  .slider-container > div {
    scroll-snap-align: start;
  }
}
