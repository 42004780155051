.progress {
  display: flex;
  gap: 10px;
  border-radius: 100px;
  &-bar {
    // background-color: grey;
    position: static !important;
    width: 100%;
    line-height: 30px;
  }
}

.footer {
  padding: 14px 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  &-profile {
    // position: fixed;
    // bottom: 1rem;
    // width: 100%;
  }
  .link {
    color: $light;
    text-decoration: none;
    white-space: nowrap;
    line-height: 2;
    margin-top: 2px;
    font-size: 9px;
    text-transform: uppercase;
    margin-bottom: -10px;
    border-bottom: 2px solid transparent;
    &:hover,
    &.active {
      .border-footer {
        border: 2px solid $primary;
        width: 100%;
        border-radius: 4px 4px 0px 0px;
      }
      span {
        color: $primary;
        font-size: 9px;
        margin-top: 2px;
        text-transform: uppercase;
        transition: filter 0.3s ease-in-out;

        .count {
          background-color: $light;
          color: $dark;
        }
      }
      // .footer {
      //   &-icon {
      //     filter: brightness(0) saturate(100%) invert(27%) sepia(99%)
      //       saturate(1653%) hue-rotate(131deg) brightness(98%) contrast(104%);
      //   }
      // }
    }
  }

  &-links {
    white-space: nowrap;
  }
  .dietary-icon {
    width: 5px;
    height: 5px;
    vertical-align: top;
    margin-left: 0.5rem;
  }

  p {
    font-size: 0.98vw;
    color: $light;
  }

  .text-link {
    color: #9b9b9b;

    &:hover {
      color: #9b9b9b;
      text-decoration: none;
    }
  }

  &-claim-form {
    // background: #F1F2F1;
    // padding: 20px 15px ;
    .link,
    p {
      color: $dark;
    }

    .link {
      font-size: 2.691vw;
      white-space: nowrap;
    }

    p {
      font-size: 4px;
    }
  }

  // .footer-pointbox {
  //   margin-top: 2.4rem;

  //   &>span {
  //     font-size: 1.3rem;
  //   }

  //   .points-box-black {
  //     background-color: $light;

  //     .points-black {
  //       color: $dark;
  //       font-size: 1.2rem;
  //     }
  //   }
  // }

  @media (min-width: 992px) {
    padding: 3.3rem 1.5rem;
    position: static;
    .link {
      font-size: 1.4rem;
    }
    .dietary-icon {
      width: 15px;
      height: 15px;
    }

    .logo {
      width: 120px;
    }

    li {
      font-size: 1.4rem;
      height: 3.4rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

.footer-pointbox {
  margin-top: 2.4rem;

  & > span {
    font-size: 1.3rem;
  }

  .points-box-black {
    background-color: $light;

    .points-black {
      color: $dark;
      font-size: 1.2rem;
    }
  }
}

.count {
  font-size: 7px;
  font-weight: bold;
  line-height: 10px;
  color: $dark;
  background-color: $light;
  border-radius: 8px;
  padding: 0 3px;
  position: absolute;
  top: -4px;
  left: 50%;
  min-width: 2rem;
  text-align: center;
  transform: translateX(-20%);
}

// Progress scss
.progress {
  &-wrapper {
    padding: 2rem 0;
    //background-color: yellow;
    position: relative;
  }

  &-bar {
    width: 100%;
    height: 0.7rem;
    // background-color: $grey-2;
    background-color: #cdcdcd;
    position: absolute;
    left: 5px;
    // left: 10px;
    right: 0;
    top: 5.3rem;
  }

  &-percentage {
    height: 100%;
    width: 10%;
    background-color: $danger;
  }

  &-bullet {
    display: flex;
    justify-content: space-between;
    list-style: none;

    &-list {
      position: relative;

      &:first-child,
      &:last-child {
        .progress-points {
          background-color: transparent;
          color: $dark;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  &-circle {
    background-color: $light;
    width: 1.8rem;
    height: 1.8rem;
    border: 0.5rem solid $grey-2;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    left: 97%;
    top: calc(100% + 1rem);
    transform: translateX(-50%);

    &.active {
      border-color: $danger;
    }
  }

  &-prize {
    display: inline-block;
    position: absolute;
    // right: 30%;
    right: 0%;
    top: calc(100% + 3rem);

    // transform: translateX(-50%);
    &.active {
      color: $danger;
    }
  }

  &-points {
    background-color: $dark;
    padding: 0.4rem;
    font-size: 1.5rem;
    line-height: 1;
    color: $light;
    text-align: center;
    border-radius: 4px;
    position: relative;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $dark;
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
    }
  }
}

// .range-3000{
//   left:100%;
// }

.range-0 {
  left: 70%;
}

.range-500 {
  left: 177%;
}

.range-prize-500 {
  right: -85%;
}

.range-300 {
  left: 140%;
}

.coin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(0);
  z-index: 999999;
  background-color: $danger;
  width: 300px;
  height: 300px;
}

@keyframes zoomInOut {
  0% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
  }

  50% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(1.5);
  }

  100% {
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
  }
}

.moveCoin {
  animation: zoomInOut 1.5s forwards;
  /* Removed 'transform 1s forwards' */
}

.range-1000 {
  left: 95%;
}

.range-prize-1000 {
  right: 5%;
}

.range-prize-300 {
  left: -30%;
}

.range-points-100 {
  left: 45%;
}

.range-points-300 {
  left: 90%;
}

.range-points-500 {
  left: 125%;
}

@media (max-width: 560px) {
  .progress-prize {
    font-size: 1rem;
  }
}
