.static {
  &-content {
    h3 {
      font-size: 1.2rem;
      line-height: 2rem;
      font-weight: 500;
      padding-bottom: 1.6rem;
    }
    p {
      font-size: 1.2rem;
      line-height: 2rem;
      padding-bottom: 1.6rem;
      font-weight: normal;
    }
    ul,
    ol {
      margin: 0;
      padding: 0 0 0 1.8rem;
      li {
        list-style: disc;
      }
    }
  }
}
@media (min-width: 768px) {
  .static {
    &-content {
      h3 {
        font-size: 2rem;
        line-height: 3.2rem;
        text-align: center;
        padding-bottom: 2.8rem;
      }
      p {
        font-size: 2rem;
        line-height: 3.2rem;
        padding-bottom: 1.6rem;
      }
      ul,
      ol {
        padding: 0 0 0 3rem;
      }
    }
  }
}
