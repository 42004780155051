//sidebar
.offcanvas {
  &-menu {
    width: 350px;
    max-width: 80%;
    transition: transform 0.25s ease-in-out;
    transform: translateX(-120%);
    position: fixed;
    height: 100%;
    background: $light;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    z-index: 16;
    padding: 2.4rem 1.4rem 7.5rem;
    &::-webkit-scrollbar {
      width: 4px;
      &-thumb {
        background-color: red;
      }
      &-track {
        background-color: transparent;
      }
    }
    &.show {
      transform: translateX(0);
    }
  }
  &-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background-color: $dark;
    opacity: 0.7;
  }
}

.user {
  &-card {
    display: flex;
    align-items: center;
  }
  &-pic {
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 100%;
  }
  &-details {
    padding-left: 1rem;
    text-align: left;
  }
  &-title {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 0.2rem;
    color: $dark;
  }
  &-coin {
    font-size: 1.2rem;
    line-height: 1;
    color: #434343;
    margin: 0;
  }
}
