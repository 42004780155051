.timer {
  display: flex;
  strong,
  small {
    color: $light;
    text-align: center;
  }
  strong {
    font-size: 2rem;
    line-height: 1;
  }
  small {
    font-size: 0.5rem;
    line-height: 1;
    display: block;
    margin-top: 0.5rem;
  }
  &-block {
    background-color: $dark;
    padding: 1.5rem;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $light;
      text-align: left;
      margin-bottom: 0;
    }
  }
}
@media (min-width: 768px) {
  .timer {
    strong {
      font-size: 2.2rem;
    }
  }
}
